import React from 'react';

import 'css/scss/pages/user/sites/index.scss';

import SiteList from 'components/siteList/siteList';

function SiteListPage() {
    return (
        <div className="site_page">
          <div className="site_list_area">
            <SiteList />
          </div>
        </div>
    )
}



export default SiteListPage;
