import React from 'react';


import 'css/scss/pages/index.scss'


import { Link } from 'react-router-dom'


class IndexPage extends React.Component {

    render() {
        return (
          <div className="index_page">
            <div className="index_page_title">動線番</div>
            <div className="index_page_buttons">
              <Link to="/register">
                <div>新規登録</div>
              </Link>
              <Link to="/login">
                <div>ログイン</div>
              </Link>
            </div>
          </div>
        );
    }
}

export default IndexPage;
