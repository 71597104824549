/**
 * iframe に対して html を書き出す
 */

class OL_iframe {

    private htmlObject:ET_NodeObject[] = [];
    private baseUrl:string = '';

    private iframeDoc:Document;

    constructor(overlookingPageData:olp_pageData){
        
        const iframeEl:HTMLIFrameElement = overlookingPageData.iframeEl;


        iframeEl.setAttribute('width','400')

        this.iframeDoc = iframeEl.contentWindow!.document;

        // データの整理
        this.htmlObject = overlookingPageData.htmlObject;
        this.baseUrl = overlookingPageData.baseUrl;

        // データ作成
        this.makeHtmlFromData()
        .then(() => {


            /**
             * dom の書き換え完了を読むために setTimeout で時間を遅らせている。
             * もちろんダサいので暫定処理
             * vue の nextTick を参考に作るといいかもしれない
             * https://aloerina01.github.io/blog/2018-09-27-1
             */
            setTimeout(() => {
                // 高さ調節
                const documentHeight = this.iframeDoc.documentElement.scrollHeight;                
                
                iframeEl.setAttribute('height',documentHeight.toString());
            },2000)

        });

    }

    /**
     * データから html を作成し、 iframe 内を挿げ替え
     * @param data 
     */
    private makeHtmlFromData = async ():Promise<void> => {

        const nodes = await this.convertChildren(this.htmlObject);

        this.iframeDoc.childNodes.forEach((node) => {
            this.iframeDoc.removeChild(node);
        })

        // base タグの設置
        const baseTag = document.createElement('base');

        baseTag.setAttribute('href',this.baseUrl);

        const headNode = nodes[0].firstChild;

        // head node の直前に設置
        headNode?.parentNode?.insertBefore(baseTag,headNode);
        
        await this.iframeDoc.appendChild(nodes[0]);


        return;

    }


    private convertChildren = (childrenObjects:ET_NodeObject[]):Promise<Node[]> => {

        return new Promise ( async (resolve) => {

            let nodeArray:Node[] = [];

            for (const childObj of childrenObjects) {

                const nodeType = childObj.nodeType;

                if(nodeType === 3){

                    if(childObj.nodeValue){
                        const textNode = document.createTextNode(childObj.nodeValue);
                        nodeArray.push(textNode);
                    }

                }else
                if(nodeType === 1){

                    if(childObj.nodeName !== 'script'){
                        const element = document.createElement(childObj.nodeName);
    
                        // element.setAttribute('data-etr-id',childObj.nodeId!.toString());
    
                        if(childObj.attributes){
                            for (const attribute of childObj.attributes) {
                                element.setAttribute(attribute.name,attribute.value)
                            }
                        }
    
                        if(childObj.children){
    
                            const children = await this.convertChildren(childObj.children);
    
                            for (const child of children) {
                                element.appendChild(child);
                            }
                        }
    
                        nodeArray.push(element);
                    }

                }else{
                    // コメントタグとかは pass
                  }
            }

            resolve(nodeArray);

        })
    }

      
}

export default OL_iframe;