/**
 * vistor の動きを制御するクラス
 * 複数の iframe に対して横断的に表示させる
 * 
 */

class Visitor {

    private visitorTimeline:olp_timelineData[];

    private currentVisitorEl:HTMLElement | null = null; // 仮置き

    private transitionElement:HTMLElement; // 遷移アニメーション用のエレメント

    private playerBaseElement:HTMLElement; // プレイヤーの包括エレメント iframe の直親

    private _animationTime:number = 400; // アニメーションの時間 このへんの設定については再考が必要

    private _currentIndex:number = -1;

    get currentIndex() { 
        return this._currentIndex 
    };

    set currentIndex(index:number){
        this._currentIndex = index;
        this.render(index);
    }

    private visitorElMap:Map<any,any> = new Map();

    private visitorElStyle = {
        position:'fixed',
        left:0,
        right:0,
        backgroundColor:'rgba(0,0,255,.3)',
        border:'2px solid #fff',
        height:'4px',
        top:0,
        zIndex:10000000
    }

    constructor(pageData:olp_pageData[],visitorData:olp_visitorData) {


        this.visitorElStyle.height = visitorData.deviceHeight + 'px';

        this.visitorTimeline = visitorData.timeline;

        // ページごとに visitorEl を設置
        // page_id と visitorEl の対応関係を作る
        pageData.forEach((data:any) => {

            const iframeEl:HTMLIFrameElement = data.iframeEl;
            

            const iframeDoc = iframeEl.contentWindow!.document;

            const visitorEl = iframeDoc.createElement('div');
            
            Object.assign(visitorEl.style,this.visitorElStyle);

            visitorEl.style.visibility = 'hidden';

            setTimeout(() => {
                iframeDoc.body.appendChild(visitorEl);
            },200)

            this.visitorElMap.set(data.page_id,visitorEl);
            
        })

        // transition 用のエレメントを作成
        this.transitionElement = document.createElement('div');

        const t_el_style = {
            width:'400px',
            height:'400px',
            borderRadius:'100%',
            border:'4px solid #000',
            backgroundColor:'red',
            position:'absolute',
            top:'0',left:'0',
            zIndex:99999999,
            opacity:'0',
        }

        Object.assign(this.transitionElement.style,t_el_style);

        // ベースエレメントに設置
        this.playerBaseElement = pageData[0].iframeEl.parentElement!;

        this.playerBaseElement.appendChild(this.transitionElement);


        // transition end イベント設置
        this.transitionElement.addEventListener('transitionend',(e) => {
            this.transitionendEvent(e);            
        })


    }


    /**
     * 指定した index の状態を書き出す
     * @param index 
     */
    private render = (index:number):void => { 
              

        const currentData:any = this.visitorTimeline[index];   

        if(!currentData) return;

        if(currentData.scrollPoint){
            // スクロール

            const scrollPoint:number = currentData.scrollPoint;
            this.currentVisitorEl!.style.top = scrollPoint.toString() + 'px';
        }

        if(currentData.clickEvents){          
            for (const clickevent of currentData.clickEvents) {
                this.clickAction(clickevent);
            }
        }

        if(currentData.action){

            if(currentData.action === 'page_transition'){
    
                // ページ遷移
                this.pageTransitionAction(currentData);

                console.log(currentData);
                
    
            }
            else
            if(currentData.action === 'exit'){
    
                // 現在の el を見えなくする
                this.currentVisitorEl!.style.backgroundColor = 'rgba(255,0,0,.3)';
    
                // リムーブ
                this.currentVisitorEl = null;
                

            }

        }


    }


    private clickAction = (clickEvent:{x:number,y:number}) => {
        const clickPointEl = document.createElement('div');

        const click_el_style = {
            width:'100px',
            height:'100px',
            borderRadius:'100%',
            border:'4px solid #000',
            backgroundColor:'yellow',
            position:'fixed',
            top:clickEvent.y + 'px',
            left:clickEvent.x + 'px',
            zIndex:99999999,
        }

        Object.assign(clickPointEl.style,click_el_style);

        this.currentVisitorEl!.ownerDocument.body.appendChild(clickPointEl);        
      
    }


    private pageTransitionAction = (currentData:any):void => {


        const nextPageId = currentData.transPageId;
        
        const nextVisitorEl = this.visitorElMap.get(nextPageId);

        // 現在の el を見えなくして

        if(this.currentVisitorEl){
            this.currentVisitorEl.style.visibility = 'hidden';
        }

        // 移動先の el を出現させる
        nextVisitorEl.style.visibility = 'visible';


        if(this.currentVisitorEl){

            // 移動アニメーション用のエレメントを発生させる
    
            // 出現場所と移動場所を算出
    
            const start_pos:any = this.getVisitorElPos(this.currentVisitorEl);
            const goal_pos:any = this.getVisitorElPos(nextVisitorEl);


            // 開始位置へ移動
            this.transitionElement.style.top = start_pos.top + 'px';
            this.transitionElement.style.left = start_pos.left + 'px';

            // 出現
            this.transitionElement.style.opacity = '1';

            setTimeout(() => {
                this.transitionElement.style.transition = `top ${this._animationTime}ms, left ${this._animationTime}ms`;

                this.transitionElement.style.top = goal_pos.top + 'px';
                this.transitionElement.style.left = goal_pos.left + 'px';   
                

            },100)
    
            
    
        }



        // フォーカスするエレメントを変更
        this.currentVisitorEl = nextVisitorEl;
    }


    private getVisitorElPos = (targetEl:HTMLElement):any => {
        // iframe 内でのoffset位置を取得
        const elTopInIframe:number = targetEl.offsetTop;
        const elLeftInIframe:number = targetEl.offsetLeft;

        // iframe のoffset位置を取得
        const baseIframe:HTMLElement = targetEl.ownerDocument.defaultView!.frameElement! as HTMLElement;

        const iframeTop:number = baseIframe.offsetTop;
        const iframeLeft:number = baseIframe.offsetLeft;

        // baseEl からの相対位置を算出
        const top:number = iframeTop + elTopInIframe;
        const left:number = iframeLeft + elLeftInIframe;
              
        return {top,left};

    }


    private transitionendEvent = (e:TransitionEvent) => {
        const target = e.target as HTMLElement;        
        target.style.transition = ''; // トランジションを切る
        target.style.opacity = '0';
    }

}

export default Visitor;