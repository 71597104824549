import React from 'react';



class UserIndexPage extends React.Component {
  
    render() {      
        return (
          <div>
            <div >userindex</div>
          </div>
        );
    }
}

export default UserIndexPage;
