import {axios_api} from 'axiosBase'

const baseUrl:string = '/logout';

class LogoutAPI{

    static logout = ():Promise< boolean > => {

        return new Promise((resolve,reject) => {
            axios_api.post(baseUrl,{})
            .then(() => {
                resolve(true);
            })
            .catch(() => {
                reject(false)
            })
        })
        
    }
}

export default LogoutAPI