import React from 'react';

import OlPlayer from 'components/ol_player/ol_player';

class Olp_page extends React.Component {
  
    render() {      
        const {params} = (this.props as any).match 
        const site_id = parseInt(params.site_id)
        return (
          <div>
            <OlPlayer site_id={site_id} />
          </div>
        );
    }
}

export default Olp_page;
