import React from 'react';

import { Link } from 'react-router-dom'

import 'css/scss/pages/user/sites/site_id/index.scss'

import Site from 'components/site/site'

function SiteIndexPage(props:any) {    
    const {params} = props.match;
    const site_id = parseInt(params.site_id);
    return (
        <div className="site_page">
          <Site site_id={site_id}/>

          <Link to={'/user/sites/' + site_id + '/olp'} >
            <div className="site_player_button">
              <span>プレイヤー</span>
            </div>
          </Link>


          <div className="back_to_sute_index_button">
            <Link to="/user/sites" >＜ 戻る</Link>
          </div>

        </div>
    );
}



export default SiteIndexPage;
