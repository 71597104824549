import {axios_api} from 'axiosBase'

const baseUrl:string = '/register';

class RegisterAPI{

    static register = (email:string,password:string):Promise< true | string[] > => {

        return new Promise((resolve,reject) => {
            axios_api.post( baseUrl ,{
                email:email,
                password:password
            })
            .then(() => {
                resolve(true);
            })
            .catch((error) => {
                let message_list:string[];

                if(error.response.status === 400){
                    message_list = error.response.data;
                }else{
                    message_list = ['登録に失敗しました'];
                }
                
                reject(message_list)
            })
        })
        
    }
}

export default RegisterAPI