import React,{useState} from 'react';

import 'css/scss/components/layouts/header/header.scss'

import HeaderMenu from 'components/layouts/header/header_menu'

function Header() {

    const [isMenuOpened,setIsMenuOpend] = useState(false as boolean);
  
    return (
        <header className="header_component">
          <div>
            <span>動線番</span>
            <span>20211226</span>
          </div>
          <div className="header_menu_open_button">
            <span onClick={() => setIsMenuOpend(true)}>menu</span>
          </div>
          <HeaderMenu isOpened={isMenuOpened} onClose={() => setIsMenuOpend(false)}/>
        </header>
    );
}



export default Header;
