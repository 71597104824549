
import OL_iframe from "./OL_iframe";

import Visitor from "./visitor";


class OverlookingPlayer {


    private visitors:Visitor[];


    private intevalId:number = 0;

    private _currentIndex:number = -1;

    get currentIndex() { 
        return this._currentIndex 
    };

    set currentIndex(index:number){
        this._currentIndex = index;
        this.visitors.forEach((visitor:Visitor) => {
            visitor.currentIndex = index;
        })
    }


    constructor (baseElement:HTMLElement,overlookingPageDatas:olp_pageData[],visitors:olp_visitorData[]) {

        overlookingPageDatas.map((overlookingPageData:any) => {

            // iframe を追加する
            const iframeEl:HTMLIFrameElement = document.createElement('iframe');
        
            baseElement.appendChild(iframeEl);
        
            overlookingPageData.iframeEl = iframeEl;

            const ol_iframes = new OL_iframe(overlookingPageData);

            return ol_iframes;

        });

        this.visitors = visitors.map((visitorData:olp_visitorData) => {            

            const visitor:Visitor = new Visitor(overlookingPageDatas,visitorData);

            return visitor;
        });        
        

    }

    public play = () => {
        
        this.intevalId = window.setInterval(() => {
            this.currentIndex += 1;
        },50);


        // document.addEventListener('click',() => {
        //     console.log('stop!');
        //     this.stop();
            
        // })
    }

    public stop = () => {
        window.clearInterval(this.intevalId);
    }
}

export default OverlookingPlayer;