import React from 'react';

import RouterComponent from './Router'


function App() {
    return (
        <div className="App">
          <RouterComponent />
        </div>
    );
}

export default App;

