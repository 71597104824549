import React from 'react';

import 'css/scss/components/siteList/site_in_siteList.scss'

import { Link } from 'react-router-dom'

const SiteComponent = (props:any) => {

    const deleteSite = () => {
        props.clickDelButton()
    }

    return (
        <div className="site_in_sitelist_component">
          <Link className="site_url" to={ window.location.pathname + '/' + props.site.id}>
            <span>{props.site.https ? 'https://' : 'http://'}</span>
            <span>{props.site.domain}</span>
          </Link>
          <div className="site_del_button" onClick={deleteSite}>削除</div>
        </div>
    );
}

export default SiteComponent;