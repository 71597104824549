import React,{useState} from 'react';

import {useHistory} from 'react-router-dom';


import 'css/scss/components/forms/register.scss'

import RegisterAPI from 'api/auth/register'


function RegisterForm() {

    // ヒストリーインスタンス
    const history = useHistory();

    const [email,setEmail] = useState('' as string);
    const [password,setPassword] = useState('' as string);
  
    const post = () => {
        RegisterAPI.register(
            email,
            password
        )
        .then(() => {
            alert('登録完了')
            history.push('/login');
        })
        .catch((error_message_list:string[]) => {
            let message_string = '';
            error_message_list.forEach(message => message_string += message + "\n");
            alert(message_string)
        })
    }

    return (
        <div className="register_form">
          <h3>新規登録</h3>
          <h4>メールアドレス</h4>
          <input name="dousenban-email" type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
          <h4>パスワード</h4>
          <input name="dousenban-password" type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
          <div className="register_submit" onClick={post}>登録</div>
        </div>
    );
}



export default RegisterForm;
