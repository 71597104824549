import React,{useEffect,useState} from 'react';

import 'css/scss/components/site/site.scss'

import SiteAPI from 'api/crud/site_crud_api'




interface SiteComponentProps {
    site_id:number
}

function Site(props:SiteComponentProps) {

    // constants
    const script_src_url = 'https://e-tracker-test-e2733.web.app/e-tracker.es.js';
    const script_src_tag = `<script type="text/javascript" src="${script_src_url}" ></script>`;

    // states
    const [site,setSite] = useState({} as Site);

    // variables
    const script_arg_tag = site.str_id ? `<script> const dousenban_script_id = \'${site.str_id}\'; </script>` : '';

    const script_tag = script_arg_tag + script_src_tag;

    // methods
    const reloadSite = ():void => {
        SiteAPI.show(props.site_id)
        .then((site:Site) => {
            setSite(site);

            console.log(site);
        })
        .catch((error) => {
        });
    }


    // mounted
    useEffect(() => {
        reloadSite()
    },[])
    

    return (
        <div className="site_component">
          <h2>
            <span>{(site.https ? 'https' : 'http') + '://'}</span>
            <span>{site.domain}</span>
          </h2>
          <textarea value={script_tag} rows={4} readOnly />
        </div>
    );
}




export default Site;
