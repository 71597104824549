import {axios_api} from 'axiosBase'

const baseUrl:string = '/login';

class LoginAPI{

    static login = (email:string,password:string):Promise< true | string[] > => {

        return new Promise((resolve,reject) => {
            axios_api.post( baseUrl ,{
                email:email,
                password:password
            })
            .then(() => {
                resolve(true);
            })
            .catch((err) => {
                reject(err)
            })
        })
        
    }
}

export default LoginAPI