import React from 'react';

import 'css/scss/pages/register.scss'


import RegisterForm from 'components/forms/register';


function RegisterPage() {
    return (
        <div className="register_page">
          <div className="register_form_wrap">
            <RegisterForm/>
          </div>
        </div>
    );
}



export default RegisterPage;
