import React from 'react';

import Header from 'components/layouts/header/header';


// interface LayoutProps {
//     route_name:string,
//     component:any,
// }

function Layout(props:any) {

    const header_none = (
        props.route_name === 'index' ||
        props.route_name === 'register' ||
        props.route_name === 'login'
    );

    return (
        <>
          {
             !header_none ? <Header /> : null
          }
          
          <props.component route_name={props.route_name} match={props.match} />
        </>
    );
}

export default Layout;

