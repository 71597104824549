import React,{useState} from 'react';

import 'css/scss/components/layouts/header/header_menu.scss'

import LogoutAPI from 'api/auth/logout'

interface HeaderMenuProps {
  isOpened:boolean,
  onClose:() => void
}

function HeaderMenu(props:HeaderMenuProps) {

    const logout = ()  => {
        LogoutAPI.logout()
        .then(() => {
            window.location.href = '/';
        });
    }

    return (
      <div className={['header_menu_component',props.isOpened ? 'is_opened' : ''].join(' ')} >
        <ul className="header_menu">
          <li onClick={() => props.onClose()}>×</li>
          <li onClick={() => logout()}>ログアウト</li>
        </ul>
      </div>
    );
}



export default HeaderMenu;
