import React,{useState} from 'react';
import {useHistory} from 'react-router-dom';

import 'css/scss/components/forms/login.scss'

import LoginAPI from 'api/auth/login';




function LoginForm() {

    // ヒストリーインスタンス
    const history = useHistory();

    const [email,setEmail] = useState('' as string);
    const [password,setPassword] = useState('' as string);
  
    const post = () => {
        LoginAPI.login(
            email,
            password
        )
        .then(() => {
            alert('ログイン完了');
            history.push('/user/sites');
        })
        .catch(() => {
            alert('ログイン失敗')
        })
    }

    return (
        <div className="login_form">
          <h3>ログイン</h3>
          <h4>メールアドレス</h4>
          <input name="dousenban-email" type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
          <h4>パスワード</h4>
          <input name="dousenban-password" type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
          <div className="login_submit" onClick={post}>ログイン</div>
        </div>
    );
}



export default LoginForm;
