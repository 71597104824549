import React from 'react';


class NotFound extends React.Component {


    render() {
        return (
          <h2>404!!!!!!!!!!?</h2>
        );
    }
}

export default NotFound;