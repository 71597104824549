import {axios_api} from 'axiosBase'

const baseUrl:string = '/sites';

class SiteListAPI{

    static index = ():Promise<Site[]> => {

        return new Promise((resolve,reject) => {
            axios_api.get(baseUrl)
            .then((result) => {
                const server_SiteList:any = result.data.site_list;

                const siteList:Site[] = server_SiteList.map((server_site:any) => {
                    
                    const siteData:Site = {
                        id:server_site.id,
                        domain:server_site.domain,
                        https:server_site.https,
                        str_id:server_site.front_str_id
                    }

                    return siteData;
                })

                resolve(siteList);
                
            })
            .catch((err) => {
                reject(err)
            })
        })
        
    }

    static show = (site_id:number):Promise<Site> => {
        return new Promise((resolve,reject) => {

            const url = baseUrl + '/' + site_id;

            axios_api.get(url)
            .then((result:any) => {

                const server_site:any = result.data.site;

                console.log(result);
                

                const siteObj:Site = {
                    id:server_site.id,
                    domain:server_site.domain,
                    https:server_site.https,
                    str_id:server_site.front_str_id
                }

                resolve(siteObj);
            })
            .catch((err) => {
                console.log(err);
            })
        })
    }

    static store = (domain:string,https:boolean):Promise<Site> => {
        return new Promise((resolve,reject) => {

            // バリデーション

            axios_api.post(baseUrl,{
                domain:domain,
                https:https
            })
            .then((result:any) => {

                const new_site_data = result.data.new_site;
                
                resolve({
                  id:new_site_data.id,
                  domain:new_site_data.domain,
                  https:new_site_data.https,
                  str_id:new_site_data.front_str_id,
                });

            })
            .catch(() => {
                reject();
            })
        })
    }


    static destroy = (site_id:number) => {

        return new Promise((resolve,reject) => {
            const url = baseUrl + '/' + site_id;
    
            axios_api.delete(url,{})
            .then((result) => {
                resolve(result);
                
            })
            .catch((error) => {
                reject();
                
            })
        })


    }
}

export default SiteListAPI