import React,{useState,useEffect,useRef} from 'react'

import {axios_api} from 'axiosBase'

import OverlookingPlayer from 'plugins/overlookingPlayer/index'
import { AxiosResponse } from 'axios'

// css
import 'css/scss/components/olplayer/olplayer.scss'

/**
 * データを取得し整形する
 * @param site_id 
 */
const get_data = (site_id:number,from_date:string = '',to_date:string = ''):Promise<any> => {

    return new Promise( async (resolve,reject) => {

        try {

            const url:string = `/sites/${site_id}/olp?from=${from_date}&to=${to_date}`;
    
            // axios 取得
            const result:AxiosResponse = await axios_api.get(url);

            if(result.status !== 200) throw new Error('データ取得エラー')
    
            const resdata = result.data;

            console.log(resdata);
            

            // pages
            const pages = resdata.pages;
              
            const pageDataList:any[] = [];
    
            for (const pageData of pages) {
    
                const data = {
                    page_id:pageData.path,
                    baseUrl:pageData.base_url,
                    htmlObject:pageData.html_data,
                };
    
                pageDataList.push(data);

            }


            // visitor
            const visitors = resdata.visitors;

            const visitorDataList:any = []


            for (const visitorData of visitors) {
                const data = {
                    id:visitorData.id,
                    deviceHeight:visitorData.device_height,
                    timeline:visitorData.timeline
                }                

                visitorDataList.push(data);
            }

            resolve({
                status:200,
                pageDataList,
                visitorDataList
            })
                
        }catch(e:any){
            console.log(e.message);
            reject(false);
                
        }
    })


}





function OlPlayer({site_id}:any) {

    const playerEl = useRef<HTMLDivElement>(null);

    const [fromDate,setFromDate] = useState('2021-08-22');
    const [toDate,setToDate] = useState('2021-08-31');

    const [playDisable,serPlayDisable] = useState(false);


    // overlookplayer インスタンス
    let olpInstance:(OverlookingPlayer | null) = null;

    const get_and_play = () => {

        if(playDisable) return;

        serPlayDisable(true)

        get_data(site_id,fromDate,toDate)
        .then((data:any) => {

            const baseElement:HTMLDivElement = playerEl.current as HTMLDivElement;

            const pageDataList = data.pageDataList;
            const visitorDataList = data.visitorDataList;
            
            olpInstance = new OverlookingPlayer(baseElement,pageDataList,visitorDataList)
            
            if(olpInstance !== null){
                olpInstance!.play();
            }
                    
        })
        .catch(() => {

        });
        
        
    }


    useEffect( () => {
              
    },[])

    return (
        <div className="olp_style">
          <input type="date" defaultValue={fromDate} onInput={(e:any) => setFromDate(e.target.value)} />
          <input type="date" defaultValue={toDate} onInput={(e:any) => setToDate(e.target.value)} />
          <button onClick={get_and_play}>再生</button>
          <div className="playerBase">
            <div ref={playerEl}>
            </div>
          </div>
        </div>
    )
} 

export default OlPlayer;


