import React from 'react';

import 'css/scss/pages/login.scss'

import LoginForm from 'components/forms/login';

function LoginPage() {
    return (
        <div className="login_page">
          <div className="login_form_wrap">
            <LoginForm/>
          </div>
        </div>
    );
}



export default LoginPage;
