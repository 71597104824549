import React,{useEffect,useState} from 'react';

import 'css/scss/components/siteList/siteList.scss';

import SiteAPI from 'api/crud/site_crud_api'

import SiteComponent from './site_in_siteList';


function SiteList(props:any) {

    const [siteList,setSiteList] = useState([] as Site[]);


    const [domainToAdd,setDomainToAdd] = useState('example.com' as string);
    const [httpsCheckToAdd,setHttpsCheckToAdd] = useState(true as boolean);

    const reloadSiteList = ():void => {
        SiteAPI.index()
        .then((list:Site[]) => {
            setSiteList([...list]);
        })
        .catch((error) => {
        });
    }

    const addSite = () => {
        SiteAPI.store(domainToAdd,httpsCheckToAdd)
        .then((new_site_data:Site) => {
            
            setSiteList([...siteList, new_site_data]);

        })
    }

    const deleteSite = (site_id:number) => {
        SiteAPI.destroy(site_id)
        .then((datda) => {

            console.log({datda});
            

            const newSiteList:Site[] = [];

            siteList.forEach((site:Site) => {
                if(site.id !== site_id){
                    newSiteList.push(site);
                }
            })

            console.log({newSiteList});

            setSiteList(newSiteList);
            

        });
    }


    useEffect(() => {
        reloadSiteList()
    },[])

    return (
        <div className="sitelist_component">
          <div className="sitelist_area">
            {siteList.map((site:Site) => {
                return (
                    <div className="sitelist_site_wrap" key={site.domain + site.id}>
                      <SiteComponent  
                      site={site} 
                      clickDelButton={() => deleteSite(site.id)}
                      />
                    </div>
                );
            })}
          </div>
          <div className="sitelist_add_area">
            <label className="sitelist_add_https_checkbox">
              <input type="checkbox" checked={httpsCheckToAdd} onChange={(e) => setHttpsCheckToAdd(e.target.checked)} />
              <span>https</span>
            </label>
            <input type="text" value={domainToAdd} onChange={(e) => setDomainToAdd(e.target.value)} />
            <div className="sitelist_add_button" onClick={addSite}>追加</div>
          </div>
        </div>
    );
}




export default SiteList;
