/**
 * axios の基本設定などをやるところ
 */

import axios from 'axios'

// const env:string = process.env.NODE_ENV;


// baseUrl 設定
const baseDomain:string = '/';
// const baseDomain:string = (env === 'development') ? `http://localhost:8090/` : '/';

const apiPath:string = 'dj'

const baseUrl:string = baseDomain + apiPath;

/**
 * django の csrf に関しての設定
 */
axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"

const api = axios.create({
    baseURL: baseUrl
});

// interceptor
api.interceptors.request.use(request => {
  
    if (!(window.navigator.onLine)) {
        alert('インターネットに接続していません');
        // throw new axios.Cancel('インターネット接続がありません');
        return;
    }

    return request
})

api.interceptors.response.use(response => {

    // 419 の対応
    return response
})

export const axios_api = api;