import React from 'react';

import { BrowserRouter as Router, Route,Switch } from 'react-router-dom';

//statics
import IndexPage from 'pages/index';
import RegisterPage from 'pages/register';
import LoginPage from 'pages/login';

// user
import UserIndexPage from 'pages/user';

// sites
import SiteListPage from 'pages/user/sites/index';
import SiteIndexPage from 'pages/user/sites/site_id/index';
import OlpPage from 'pages/user/sites/site_id/olp';


// 404
import NotFound from 'pages/notFound';


import Layout from 'Layout'


function RouterComponent() {

    const route_list = [
        { name:'index',path:'/',component:IndexPage },
        { name:'register',path:'/register',component:RegisterPage },
        { name:'login',path:'/login',component:LoginPage },
        { name:'user_index',path:'/user',component:UserIndexPage },
        { name:'site_list',path:'/user/sites',component:SiteListPage },
        { name:'site_index',path:'/user/sites/:site_id',component:SiteIndexPage },
        { name:'olp',path:'/user/sites/:site_id/olp',component:OlpPage }
    ]

    return (
        <>
          <Router>
            <Switch>
              {route_list.map((route) => {
                  return (
                      <Route 
                        exact path={route.path} 
                        key={route.path}
                        render={(routeProps) => <Layout {...routeProps} route_name={route.name} component={route.component} /> }
                      />
                  )
              })}
              <Route component={NotFound} />
            </Switch>
          </Router>
        </>
    );
}

export default RouterComponent;

